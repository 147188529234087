import React, { createContext } from 'react';
import { Ability } from '@casl/ability';
import { createContextualCan, useAbility } from '@casl/react';
import propTypes from 'prop-types';
import { useMe } from 'hooks/useMe';

const Context = createContext();

function usePermissions() {
    const permissions = useAbility(Context);
    if (permissions === undefined) {
        throw new Error('usePermissions should be used within a PermissionProvider');
    }
    return permissions;
}

const PermissionProvider = ({ children }) => {
    const { whoami } = useMe();
    return <Context.Provider value={whoami ? whoami.permissions : new Ability()}>{children}</Context.Provider>;
};

PermissionProvider.propTypes = {
    children: propTypes.node
};

export { usePermissions, PermissionProvider };
export const Can = createContextualCan(Context.Consumer);
