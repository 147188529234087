// action - state management
import * as actionTypes from './actions';

export const initialState = { breadcrumbs: null, info: null };

// ==============================|| BREADCRUMB REDUCER ||============================== //

const breadcrumbReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_BREADCRUMBS:
            return {
                ...state,
                ...action.payload
            };
        case actionTypes.RESET_BREADCRUMBS:
            return initialState;
        default:
            return state;
    }
};

export default breadcrumbReducer;
