import { useDispatch } from 'react-redux';
import { SET_BREADCRUMBS, RESET_BREADCRUMBS } from 'store/actions';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

export const useBreadcrumbs = (payload) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({ type: SET_BREADCRUMBS, payload });
        return () => {
            dispatch({ type: RESET_BREADCRUMBS });
        };
    }, [payload, dispatch]);
};

useBreadcrumbs.propTypes = {
    breadcrumbs: PropTypes.array
};
