/* eslint-disable */
import format from 'date-fns/format';

function containsOnlyNumbers(str) {
    return /^\d+$/.test(str);
}

function dateValuesArray(string) {
    let array = [];
    let error = false;
    if (string.includes('/')) array = string.split('/');
    else if (string.includes('-')) array = string.split('-');
    else if (string.includes('.')) array = string.split('.');
    else if (string.includes(',')) array = string.split(',');
    else error = true;

    array.forEach((value) => {
        if (!containsOnlyNumbers(value)) {
            error = true;
        }
    });
    if (error) return 'error';
    else return array;
}

function isValidDate(year, month, day) {
    var d = new Date(year, month, day);
    if (d.getFullYear() === Number(year) && d.getMonth() === Number(month) && d.getDate() === Number(day)) {
        return true;
    }
    return false;
}

function decideYear(year) {
    if (year?.length === 4) return year;
    else if (year?.length === 2)
        if (year < 50) return '20' + year;
        else return '19' + year;
    else return false;
}

function formatToShowDate(
    string,
    dateFormat = { format: 'dd/MM/yyyy', d: 0, m: 1, y: 2 },
    defaultValue,
    dateInUse = false,
    dateFrom,
    dateTo
) {
    const dateParsed = dateValuesArray(string);
    let year;
    let yearParsed = dateParsed[dateFormat?.y] ?? new Date().getFullYear().toString();
    if (decideYear(yearParsed)) year = decideYear(yearParsed);
    else {
        if (defaultValue) return format(new Date(defaultValue), dateFormat?.format);
        else return null;
    }
    let month = dateParsed[dateFormat?.m] - 1;
    let day = dateParsed[dateFormat?.d];
    if (dateParsed === 'error') {
        if (defaultValue) return format(new Date(defaultValue), dateFormat?.format);
        else return null;
    }
    if (isValidDate(year, month, day)) {
        if (dateInUse) return inDateRange(year, month, day, dateFrom, dateTo, dateFormat?.format);
        else return format(new Date(year, month, day), dateFormat?.format);
    } else if (isValidDate(year, day - 1, month + 1))
        if (dateInUse) return inDateRange(year, day - 1, month + 1, dateFrom, dateTo, dateFormat?.format);
        else return format(new Date(year, day - 1, month + 1), dateFormat?.format);
    else {
        if (defaultValue) return format(new Date(defaultValue), dateFormat?.format);
        else return null;
    }
}

function inDateRange(year, month, day, dateFrom, dateTo, dateFormat = 'dd/MM/yyyy') {
    if (new Date(year, month, day) >= new Date(dateFrom) && new Date(year, month, day) <= new Date(dateTo))
        return format(new Date(year, month, day), dateFormat);
    else if (new Date(year, month, day) < new Date(dateFrom)) return format(new Date(dateFrom), dateFormat);
    else if (new Date(year, month, day > new Date(dateTo))) return format(new Date(dateTo), dateFormat);
    else return null;
}

function validTime(timeArray) {
    if (containsOnlyNumbers(timeArray[0]) && containsOnlyNumbers(timeArray[1]))
        if (timeArray[0] < 0 || timeArray[0] > 23 || timeArray[1] < 0 || timeArray[1] > 59) return 'errorTime';
        else return true;
    else return 'errorTime';
}

function formatToShowDateTime(
    string,
    dateFormat = { format: 'dd/MM/yyyy', d: 0, m: 1, y: 2 },
    dateTimeFormat = 'dd/MM/yyyy HH:mm',
    defaultDate,
    defaultTime
) {
    const dateParsed = dateTimeValuesArray(string)?.date;
    const timeParsed = dateTimeValuesArray(string)?.time;
    let datePart, timePart;

    let year, month, day;
    let yearParsed = dateParsed[dateFormat?.y] ?? new Date().getFullYear().toString();

    // check year, if year is ok and store month and day
    if (decideYear(yearParsed)) {
        year = decideYear(yearParsed);
        month = dateParsed[dateFormat?.m] - 1;
        day = dateParsed[dateFormat?.d];
    }
    // if year is not okay then date is error
    else {
        if (defaultDate) datePart = new Date(defaultDate);
        else datePart = null;
    }
    // check if date was wrong from the beggining
    if (dateParsed === 'error') {
        if (defaultDate) datePart = new Date(defaultDate);
        else datePart = null;
    }
    // check if date is valid
    if (isValidDate(year, month, day)) datePart = new Date(year, month, day);
    // check if day and month were written opposite
    else if (isValidDate(year, day - 1, month + 1)) datePart = new Date(year, day - 1, month + 1);
    // invalid Date
    else {
        if (defaultDate) datePart = new Date(defaultDate);
        else datePart = null;
    }
    if (timeParsed) {
        if (validTime(timeParsed)) timePart = timeParsed;
        else if (defaultTime) timePart = defaultTime?.split(':');
        else timePart = null;
    } else if (defaultTime) timePart = defaultTime?.split(':');
    else timePart = null;

    if (datePart && timePart) {
        datePart.setHours(timePart[0], timePart[1]);
        return format(datePart, dateTimeFormat);
    } else return format(datePart ?? new Date(), dateTimeFormat);
}

function dateFormatDetails(format) {
    let array = [];
    let d, m, y, seperator;
    if (format.includes('/')) {
        array = format.split('/');
        seperator = '/';
    } else if (format.includes('-')) {
        array = format.split('-');
        seperator = '-';
    } else if (format.includes('.')) {
        array = format.split('.');
        seperator = '.';
    }
    if (array?.indexOf('d') > -1) d = array?.indexOf('d');
    if (array?.indexOf('dd') > -1) d = array?.indexOf('dd');
    if (array?.indexOf('M') > -1) m = array?.indexOf('M');
    if (array?.indexOf('MM') > -1) m = array?.indexOf('MM');
    if (array?.indexOf('yyyy') > -1) y = array?.indexOf('yyyy');
    if (array?.indexOf('yy') > -1) y = array?.indexOf('yy');
    let formatArray = [];
    formatArray[d] = 'day';
    formatArray[m] = 'month';
    formatArray[y] = 'year';

    return {
        format,
        d,
        m,
        y,
        seperator,
        formatArray
    };
}

function dateTimeValuesArray(string) {
    let array = [],
        date,
        time;
    if (string.includes(' ')) {
        array = string.split(' ');
        date = array[0];
        time = array[1];
    } else return { date: dateValuesArray(string), time: null };
    date = dateValuesArray(array[0]);
    if (array[1].includes(':')) time = array[1].split(':');
    else if (array[1].includes('.')) time = array[1].split('.');
    else if (array[1]?.length === 4) time = array[1].match(/.{1,2}/g);
    else time = null;
    return {
        date,
        time
    };
}

export function calculate(startDateOfUse, endDateOfUse) {
    let yearOfStartDate = new Date(startDateOfUse).getFullYear();
    let yearOfEndDate = new Date(endDateOfUse).getFullYear();
    let yearOfCurrentDate = new Date().getFullYear();

    //let yearOfStartDate = StartDate.getfullYear();
    //let yearOfEndDate = EndDate.getfullYear();
    // let yearOfCurrentDate = CurrentDate.getfullYear();

    let monthOfStartDate = new Date(startDateOfUse).getMonth();
    let monthOfEndDate = new Date(endDateOfUse).getMonth();
    let monthOfCurrentDate = new Date().getMonth();

    let dayOfEndDate = new Date(endDateOfUse).getDate();
    let dayOfCurrentDate = new Date().getDate();

    let firstDate;
    let lastDate;
    let resultMonth;

    if (yearOfStartDate !== yearOfEndDate) {
        if (yearOfStartDate <= yearOfCurrentDate <= yearOfEndDate) {
            if (monthOfStartDate !== monthOfEndDate) {
                if (monthOfStartDate <= monthOfCurrentDate <= monthOfEndDate) {
                    if (monthOfCurrentDate === 0) {
                        firstDate = new Date(yearOfCurrentDate, monthOfCurrentDate, 1);
                        lastDate = new Date(yearOfCurrentDate, monthOfCurrentDate, dayOfEndDate);
                    } else {
                        resultMonth = monthOfCurrentDate - 1;
                        firstDate = new Date(yearOfCurrentDate, resultMonth, 1);
                        lastDate = new Date(yearOfCurrentDate, resultMonth + 1, 0);
                    }
                }
            } else {
                if (monthOfStartDate >= monthOfCurrentDate) {
                    if (monthOfCurrentDate === 0) {
                        firstDate = new Date(yearOfCurrentDate, monthOfCurrentDate, 1);
                        lastDate = new Date(yearOfCurrentDate, monthOfCurrentDate, dayOfEndDate);
                    } else {
                        resultMonth = monthOfCurrentDate - 1;
                        firstDate = new Date(yearOfCurrentDate, resultMonth, 1);
                        lastDate = new Date(yearOfCurrentDate, resultMonth + 1, 0);
                    }
                }
            }
        }
    } else {
        if (yearOfStartDate >= yearOfCurrentDate) {
            if (monthOfStartDate !== monthOfEndDate) {
                if (monthOfStartDate <= monthOfCurrentDate <= monthOfEndDate) {
                    if (monthOfCurrentDate === 0) {
                        firstDate = new Date(yearOfCurrentDate, monthOfCurrentDate, 1);
                        lastDate = new Date(yearOfCurrentDate, monthOfCurrentDate, dayOfCurrentDate);
                    } else {
                        resultMonth = monthOfCurrentDate - 1;
                        firstDate = new Date(yearOfCurrentDate, resultMonth, 1);
                        lastDate = new Date(yearOfCurrentDate, resultMonth + 1, 0);
                    }
                }
            } else {
                if (monthOfStartDate >= monthOfCurrentDate) {
                    if (monthOfCurrentDate === 0) {
                        firstDate = new Date(yearOfCurrentDate, monthOfCurrentDate, 1);
                        lastDate = new Date(yearOfCurrentDate, monthOfCurrentDate, dayOfEndDate);
                    } else {
                        resultMonth = monthOfCurrentDate - 1;
                        firstDate = new Date(yearOfCurrentDate, resultMonth, 1);
                        lastDate = new Date(yearOfCurrentDate, resultMonth + 1, 0);
                    }
                } else {
                    firstDate = new Date(yearOfStartDate, monthOfStartDate, 1);
                    lastDate = new Date(yearOfStartDate, monthOfStartDate, dayOfEndDate);
                }
            }
        } else {
            firstDate = new Date(yearOfStartDate, 11, 1);
            lastDate = new Date(yearOfStartDate, 11 + 1, 0);
        }
    }
    firstDate = format(firstDate, 'yyyy-MM-dd');
    lastDate = format(lastDate, 'yyyy-MM-dd');
    return { firstDate, lastDate };
}

export function validDate(startDateOfUse, endDateOfUse, currentDate) {
    let yearOfStartDate = new Date(startDateOfUse).getFullYear();
    let yearOfEndDate = new Date(endDateOfUse).getFullYear();
    let yearOfCurrentDate = new Date(currentDate).getFullYear();

    let monthOfStartDate = new Date(startDateOfUse).getMonth();
    let monthOfEndDate = new Date(endDateOfUse).getMonth();
    let monthOfCurrentDate = new Date(currentDate).getMonth();

    let dayOfStartDate = new Date(startDateOfUse).getDate();
    let dayOfEndDate = new Date(endDateOfUse).getDate();
    let dayOfCurrentDate = new Date(currentDate).getDate();

    let valid = false;

    if (yearOfStartDate !== yearOfEndDate) {
        if (yearOfStartDate <= yearOfCurrentDate <= yearOfEndDate) {
            valid = true;
        }
    } else {
        if (yearOfStartDate > yearOfCurrentDate) {
            valid = true;
        } else if (yearOfStartDate === yearOfCurrentDate) {
            if (monthOfStartDate !== monthOfEndDate) {
                if (monthOfStartDate <= monthOfCurrentDate <= monthOfEndDate) {
                    valid = true;
                }
            } else if (monthOfStartDate > monthOfCurrentDate) {
                valid = true;
            } else if (monthOfStartDate === monthOfCurrentDate) {
                if (dayOfStartDate < dayOfCurrentDate && dayOfCurrentDate <= dayOfEndDate) {
                    valid = true;
                }
            }
        }
    }

    return valid;
}

export function lastDayofMonth(givenDay) {
    let yearofGivenDay = new Date(givenDay).getFullYear();
    let monthofGivenDay = new Date(givenDay).getMonth();

    let lastDateofMonth = new Date(yearofGivenDay, monthofGivenDay + 1, 0);

    lastDateofMonth = format(lastDateofMonth, 'yyyy-MM-dd');
    return lastDateofMonth;
}

export function firstDayofMonth(givenDay) {
    let yearofGivenDay = new Date(givenDay).getFullYear();
    let monthofGivenDay = new Date(givenDay).getMonth();

    let firstDateofMonth = new Date(yearofGivenDay, monthofGivenDay, 1);

    firstDateofMonth = format(firstDateofMonth, 'yyyy-MM-dd');
    return firstDateofMonth;
}

export function previousMonthsDetails(givenDay) {
    let yearofGivenDay = new Date(givenDay).getFullYear();
    let monthofGivenDay = new Date(givenDay).getMonth();

    let previousMonth = monthofGivenDay - 1;

    let previousFirstDateofMonth = new Date(yearofGivenDay, previousMonth, 1);
    let previousLastDateofMonth = new Date(yearofGivenDay, previousMonth + 1, 0);

    previousFirstDateofMonth = format(previousFirstDateofMonth, 'yyyy-MM-dd');
    previousLastDateofMonth = format(previousLastDateofMonth, 'yyyy-MM-dd');

    return { previousFirstDateofMonth, previousLastDateofMonth };
}

export {
    containsOnlyNumbers,
    isValidDate,
    dateValuesArray,
    decideYear,
    formatToShowDate,
    formatToShowDateTime,
    dateFormatDetails,
    inDateRange
};
