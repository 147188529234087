import { Box, Typography } from '@mui/material';

const RowLabel = ({ label, labelWidth = 120 }) => {
    if (typeof label === 'string')
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 1 }}>
                <Typography
                    align="right"
                    sx={{
                        justifyContent: 'center',
                        width: labelWidth,
                        fontStyle: 'italic'
                    }}
                >{`${label}:`}</Typography>
            </Box>
        );
    return `${label}:`;
};

export default RowLabel;
