import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
import axiosInstance from 'apis/axiosInstance';
import { RESET_MENU, LOGOUT } from 'store/actions';
import { store } from 'store';
import { auth } from 'apis';
import { useNavigate } from 'react-router-dom';

export const useLogout = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const authState = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const logout = useCallback(async () => {
        const refreshToken = authState.refreshToken;
        // notify backend to blacklist token
        await auth.logout({ refreshToken }).catch((err) => console.error(err));
        // reset store
        dispatch({ type: RESET_MENU });
        dispatch({ type: LOGOUT });
        // reset axios interceptors
        axiosInstance.interceptors.request.use((config) => {
            const state = store.getState();
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${state.auth.token}`
            };
            return config;
        });
        // cancel queries
        queryClient.cancelQueries('me');
        navigate('/');
    }, [authState, dispatch, queryClient]);

    return { logout };
};
