import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SET_LANGUAGE } from 'store/actions';

//  i18next
import { useTranslation } from 'react-i18next';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';

// project imports
import Wrapper from './Wrapper';

// ==============================|| LANGUAGE ||============================== //

const LanguageSection = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { language } = useSelector((state) => state.customization);
    const [selectedLanguage, setLanguage] = useState(language);
    const { i18n, t } = useTranslation();

    const handleLanguageChange = (e) => {
        setLanguage(e.target.value);
        dispatch({ type: SET_LANGUAGE, language: e.target.value });
        i18n.changeLanguage(e.target.value);
    };

    const LanguageRadioGroup = () => (
        <FormControl>
            <RadioGroup aria-label="language" value={selectedLanguage} onChange={handleLanguageChange} name="row-radio-buttons-group">
                <FormControlLabel
                    value="en"
                    control={<Radio />}
                    label="English"
                    sx={{
                        '& .MuiSvgIcon-root': { fontSize: 28 },
                        '& .MuiFormControlLabel-label': { color: theme.palette.grey[900] }
                    }}
                />
                <FormControlLabel
                    value="gre"
                    control={<Radio />}
                    label="Ελληνικά"
                    sx={{
                        '& .MuiSvgIcon-root': { fontSize: 28 },
                        '& .MuiFormControlLabel-label': { color: theme.palette.grey[900] }
                    }}
                />
            </RadioGroup>
        </FormControl>
    );

    return (
        <Wrapper>
            <Grid container direction="column" spacing={2}>
                <Grid item xs={12}>
                    <Stack spacing={2} sx={{ p: 3 }}>
                        <Typography variant="h4" color="primary">
                            {t('SelectLanguage')}
                        </Typography>
                        <LanguageRadioGroup />
                    </Stack>
                </Grid>
            </Grid>
            <Divider />
        </Wrapper>
    );
};

export default LanguageSection;
