import React from 'react';
import PropTypes from 'prop-types';

const Conditional = ({ condition, children, hide }) => {
    if (hide) {
        return <div hidden={!condition}>{children}</div>;
    }
    return condition ? <>{children}</> : null;
};

Conditional.propTypes = {
    condition: PropTypes.bool.isRequired,
    children: PropTypes.node
};

export default Conditional;
