import { MinimalWrapper } from 'ui-component/wrappers';
import { Stack, Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import LanguageSection from 'layout/MainLayout/Header/LanguageSection';
// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => (
    <MinimalWrapper>
        <Stack alignItems="end" sx={{ p: 1 }}>
            <Box sx={{ p: 1, background: 'white', borderRadius: 2 }}>
                <LanguageSection />
            </Box>
            <Outlet />
        </Stack>
    </MinimalWrapper>
);

export default MinimalLayout;
